<template>
    <div class="bg-gray-100 flex items-center justify-center h-screen">
    <div class="bg-white p-8 rounded-lg shadow-md">
        <h1 class="text-2xl font-semibold mb-4">Unauthorized Access</h1>
        <p class="text-gray-600 mb-4">You don't have permission to access this page.</p>
        <a href="/login" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800">Login</a>
    </div>
</div>
</template>
<script setup>
</script>