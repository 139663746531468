<template>
    <div>
        <footer class="bg-gray-800 text-white py-4">
    <div class="container mx-auto text-center md:flex md:justify-between">
      <p class="text-sm">&copy; 2023 Car Rental System</p>
      <p class="text-sm mt-2">Address: 1234 Main Street, City, Country</p>
      <p class="text-sm mt-2">Phone: +1 (123) 456-7890</p>
    </div>
  </footer>
    </div>
</template>
<script setup>
</script>